import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SanityImage from 'gatsby-plugin-sanity-image-alt'

import loadable from '@loadable/component'

import Map from '../images/map.svg'
import Shape from '../svg/shape-banner.svg'

import { useWindowSize } from '../helpers/useWindowSize'
import { useSanityImageShape } from '../helpers/useSanityImageShape'

const Buttons = loadable(() => import('./Buttons'))
const PortableText = loadable(() => import('./PortableText'))

const PageBuilderBanner = ({ block, index }) => {
  const {
    title,
    text,
    invertAlign,
    extraBottomPadding,
    showMap,
    buttons,
    bgImg,
  } = block

  const buttonsContainer = buttons

  const windowSize = useWindowSize()
  const isMobile = windowSize.width <= 768

  const directionMap = invertAlign
    ? 'flex-col-reverse  md:flex-row-reverse'
    : 'flex-col-reverse'
  const direction = invertAlign ? 'flex-col-reverse' : 'flex-col md:flex-row'

  const backgroundShaped = useSanityImageShape(bgImg)

  return (
    <>
      <section
        className={`relative w-full ${
          showMap
            ? `mt-48 md:mt-0 md:h-heading-m-tall ${
                extraBottomPadding ? 'mb-12' : ''
              }`
            : 'h-heading-m md:h-heading-d'
        } flex`}
      >
        <div className="absolute inset-0 h-full w-full bg-gray-200 text-gray-100">
          {backgroundShaped && (
            <SanityImage
              {...backgroundShaped}
              className="object-cover w-full h-full"
              alt={`${title} - header`}
              width={isMobile ? 500 : 1920}
              height={isMobile ? 520 : 1080}
            />
          )}
          <Shape
            className="absolute top-50 left-50 w-full min-w-screen-lg -ml-16 md:ml-0 transform -translate-x-1/2 -translate-y-1/2 z-1"
            style={{ height: `calc(100% + 2px)` }}
          />
          <div className="absolute bg-black bg-opacity-35 inset-0 h-full w-full" />
        </div>
        <div className="container grid grid-cols-12 z-10 pb-12 py-8 md:py-16 md:pb-24">
          <div
            className={`col-span-12 xl:col-span-10 xl:col-start-2 md:-mx-4 md:items-end flex ${
              showMap ? directionMap : direction
            }`}
          >
            <div className="md:w-1/2 md:mx-4 mt-auto">
              {title && (
                <h2 className="text-24 md:text-30 font-bold text-white md:transform md:-translate-y-4 ">
                  {title}
                </h2>
              )}
              {text && (
                <div className="grid grid-cols-12">
                  <div className="mb-8 col-span-12">
                    <PortableText blocks={text} isLight />
                  </div>
                </div>
              )}
              {buttons?.length > 0 && <Buttons buttons={buttons} />}
            </div>
            {showMap && (
              <div className="md:w-1/2 -mb-12 transform -translate-y-32 md:translate-y-0 -translate-x-20 md:translate-x-0 scale-105 md:absolute xl:-top-12 md:-left-10pc lg:-left-5pc md:max-w-map-md lg:max-w-map-lg">
                <img className="w-full" src={Map} alt="Soul Valley Map" />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

PageBuilderBanner.propTypes = {
  block: PropTypes.object,
  index: PropTypes.number,
}

PageBuilderBanner.defaultProps = {
  block: ``,
  index: 0,
}

export default PageBuilderBanner
